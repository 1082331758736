<div class="uib-textfield" (click)="onOpenMapClick($event)">
  <input
    placeholder="-"
    type="text"
    class="p-inputtext ellipsis-text"
    disabled
    value="{{
      selectedCoordinates
        ? mapService.convertToDMS(
            selectedCoordinates?.lat,
            selectedCoordinates?.lng,
            false,
            false
          )
        : undefined
    }}"
  />
  <button (click)="onOpenMapClick($event)" type="button" class="map-button">
    <uib-icon name="uib-icon--map" size="small"> </uib-icon>
  </button>
</div>

<span
  *ngIf="selectedCoordinates?.lng"
  class="p-dropdown-clear-icon pi pi-times reset-value"
  (click)="resetCoordinates()"
></span>

<ng-template #confirmLocationModalTemplate let-confirmLocationModalReference>
  <div (click)="$event.stopPropagation()">
    <odx-modal-header
      ><odx-area-header
        >{{ "select_coordinates" | translate }}
      </odx-area-header></odx-modal-header
    >
    <odx-modal-content>
      <div class="map-container" *ngIf="mapApiLoaded">
        <google-map
          [height]="'100%'"
          width="'100%'"
          [options]="mapOptions"
          (mapClick)="addMarker($event)"
          (authFailure)="mapErrorHandling()"
        >
          <map-marker
            #markerElem
            [position]="marker.position"
            [options]="markerOptions"
          >
          </map-marker>
        </google-map>
      </div>
    </odx-modal-content>
    <odx-modal-footer class="confirm_footer" s>
      <button
        odxButton
        variant="highlight"
        [odxModalClose]="true"
        [disabled]="isLoading | async"
      >
        {{ "confirm" | translate }}
      </button>
      <button odxButton [odxModalClose]="false" variant="secondary">
        {{ "cancel" | translate }}
      </button>
    </odx-modal-footer>
  </div>
</ng-template>
