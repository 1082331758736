import { Router } from '@angular/router';
import { AfterViewInit, Component, Input, ViewChild, Output, SimpleChanges, TemplateRef, EventEmitter, OnChanges } from '@angular/core';
import { Table } from 'primeng/table';
import * as _ from 'lodash';
import { TableConstants } from '../../constants/table.constants';
import { LazyLoadEvent } from 'primeng/api';
import { TableService } from '../../services/table/table.service';
import { DropdownService } from '../../services/dropdown/dropdown.service';
import { ITestDataFields } from 'src/app/test-data-drugcheck/models/test-data-drugcheck.model';

@Component({
  selector: 'draeger-table-v2',
  templateUrl: './table-v2.component.html',
  styleUrls: ['./table-v2.component.sass']
})
export class TableV2Component implements OnChanges, AfterViewInit {

  @ViewChild('draegerTable')
  public draegerTable: Table | any;

  @Input() tableColumns: ITestDataFields[];
  @Input() dataTable: any;
  @Input() scrollable: boolean = true;

  @Input() datePickerFormat: string;
  @Input() datePickerSelectionMode: string;
  @Input() datePickerShowTime: boolean = false;
  @Input() datePickerShowSeconds: boolean = false;
  @Input() readOnlyDatePicker: boolean = false;

  @Input() filtersList: any;
  @Input() dropdownActiveOption: any;
  @Input() defaultRange: any;
  searchedItem: string;


  @Input() lazy: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() paginator: boolean = false;
  @Input() displayFilters: boolean = false;
  @Input() customColumnsLayout: string[];
  @Input() customColumnsRef: TemplateRef<any[]>;
  @Input() customFilterLayout: string[];
  @Input() filterDelay: number = 600;
  @Input() savedFiltersName: string;
  @Input() columnResizeMode: string = 'fit';
  @Input() rows: number = 25;
  @Input() first: number = 0;
  @Input() rowsPerPageOptions: number[] = TableConstants.rowsPerPageOptions;
  @Input() totalRecords: number;
  @Input() routeUrl: string;


  @Input() disableTooltip: boolean;
  @Input() selectedCoordinates: string | any;

  @Output() handleTableRef: EventEmitter<any> = new EventEmitter();
  @Output() handleRowSelection: EventEmitter<any> = new EventEmitter();
  @Output() handleColumnVisibility: EventEmitter<ITestDataFields[]> = new EventEmitter();
  @Output() handleOnPageChange: EventEmitter<{ pageNumber: number; rows: number }> = new EventEmitter();
  @Output() handleOnLazyLoading: EventEmitter<LazyLoadEvent> = new EventEmitter();
  @Output() handleOnSortChange: EventEmitter<any> = new EventEmitter();
  @Output() handleFilterValue: EventEmitter<any> = new EventEmitter();

  @Input() sortColumn: any;
  selectedData: any;
  loadDataEvent: LazyLoadEvent;
  @Input() pageNumber: number = 0;
  lastTableElement: any;

  gpsPosition: string[];
  defaultGpsPosition: string = '0° 0\' 0.00000" N,0° 0\' 0.00000" E';

  alcotestIcons = [
    { value: 'mouthpiece_tube', path: "./assets/img/alcotest_tube.svg", alt: "Alcotest mouthpiece tube icon", tooltip: 'Mouthpiece tube' },
    { value: 'mouthpiece_funnel', path: "./assets/img/alcotest_funnel.svg", alt: "Alcotest mouthpiece funnel icon", tooltip: 'Mouthpiece funnel' },
    { value: 'trigger_passive', path: "./assets/img/alcotest_passive.svg", alt: "Alcotest trigger passive icon", tooltip: 'Passive' },
    { value: 'trigger_flow', path: "", alt: "Alcotest trigger flow icon" },
    { value: 'trigger_manual', path: "./assets/img/alcotest_manual.svg", alt: "Alcotest trigger manual icon", tooltip: 'Manual' },
    { value: 'trigger_not_applied', path: "", alt: "Alcotest trigger not applied icon" },
    { value: "workflow", path: "./assets/img/workflow.svg", alt: "Workflow icon", tooltip: 'Workflow' }
  ]

  constructor(public tableService: TableService, public dropdownService: DropdownService, private router: Router) { }

  get visibleColumns(): ITestDataFields[] {
    if (this.tableColumns) {
      return this.tableColumns.filter((col: ITestDataFields) => col.is_displayed && col.can_be_displayed);
    }
  }

  get tableStoredFilters(): any {
    return localStorage.getItem(this.savedFiltersName) ? JSON.parse(localStorage.getItem(this.savedFiltersName)) : null;
  }

  ngOnChanges(changes: SimpleChanges | any): void {
    if (this.tableColumns && this.dataTable) {
      const mutableDataTable: any = JSON.parse(JSON.stringify(this.dataTable));
      mutableDataTable.forEach((data: any) => {
        this.tableColumns.forEach((column: ITestDataFields) => {
          data[column.key] = _.get(data, column.key);
        })
      });

      this.dataTable = mutableDataTable;

      this.lastTableElement = this.tableService.lastTableHeaderElemV2(this.tableColumns);

    }
  }

  ngAfterViewInit(): void {
    this.handleTableRef.emit(this.draegerTable);
    this.tableService.arrangePaginingOfElement(this.draegerTable);

    setTimeout(() => {
      this.setSelectedRow();
    },);
  }

  setSelectedRow() {
    if (localStorage.hasOwnProperty('selectedTestDataDrugCheckDetails')) {
      const item = JSON.parse(localStorage.getItem('selectedTestDataDrugCheckDetails'));

      this.selectedData = item;
      this.handleRowSelection.emit(item);
      localStorage.removeItem('selectedTestDataDrugCheckDetails');
    }
    else if (localStorage.hasOwnProperty('selectedTestDataAlcotestDetails')) {
      const item = JSON.parse(localStorage.getItem('selectedTestDataAlcotestDetails'));

      this.selectedData = item;
      this.handleRowSelection.emit(item);
      localStorage.removeItem('selectedTestDataAlcotestDetails');
    }
  }

  onPageOrRowChange(event: { first: number; rows: number }): void {
    this.removeSelectedRow()
    this.first = event.first;
    this.rows = event.rows;

    this.pageNumber = event.first / event.rows;
    this.handleOnPageChange.emit({
      pageNumber: this.pageNumber,
      rows: this.rows
    });
  }

  removeSelectedRow(): void {
    if (!this.router.url.includes(this.routeUrl)) {
      this.selectedData = null;
      this.handleRowSelection.emit(null)
    }
  }

  onSelect(column: any): void {
    if (column) {
      if (this.selectedData?.id === column?.id) {
        this.selectedData = null;
        this.handleRowSelection.emit(null);
      } else {
        this.selectedData = column;
        this.handleRowSelection.emit(column);
      }
    }
  }

  //no filters yet
  onLazyLoadingData(event: LazyLoadEvent): void {
    /* istanbul ignore if */
    if (localStorage.hasOwnProperty('testDataDrugCheckTableFilters')) {
      let eventFromLocalStorage = {};
      const params = JSON.parse(localStorage.getItem('testDataDrugCheckTableFilters'));

      eventFromLocalStorage = {
        first: params.first,
        rows: params.rows,
        sortOrder: params.order,
        sortField: params.sortField,
      };

      setTimeout(() => {
        this.first = params.first;
        this.rows = params.rows
        this.sortColumn = {
          field: params.field,
          order: params.order
        };
      });

      this.loadDataEvent = eventFromLocalStorage;
    } else if (localStorage.hasOwnProperty('testDataAlcotestTableFilters')) {
      let eventFromLocalStorage = {};
      const params = JSON.parse(localStorage.getItem('testDataAlcotestTableFilters'));

      eventFromLocalStorage = {
        first: params.first,
        rows: params.rows,
        sortOrder: params.order,
        sortField: params.sortField,
      };

      setTimeout(() => {
        this.first = params.first;
        this.rows = params.rows
        this.sortColumn = {
          field: params.field,
          order: params.order
        };
      });

      this.loadDataEvent = eventFromLocalStorage;
    } else {
      this.loadDataEvent = event;
    }

    this.handleOnLazyLoading.emit(this.loadDataEvent);

    if (event.rows !== this.rows) {
      this.draegerTable._first = 0;
    }
  }

  onTextFilterChange(event: any, field: string) {
    this.removeSelectedRow()
    this.handleFilterValue.emit({ [field]: event });
  }

  onDropdownChange(event: any, field: string, options?: any) {
    this.removeSelectedRow()
    this.handleFilterValue.emit({ [field]: event.value });
  }

  onMultiSelectChange(event: any[], field: string): void {
    this.removeSelectedRow()
    this.handleFilterValue.emit({ [field]: event });
  }

  onDateFilterSelect(event: Date[], field: string): void {
    this.removeSelectedRow()
    this.handleFilterValue.emit({ [field]: event });
  }

  onDateFilterChange(field: string): void {
    this.removeSelectedRow()
    this.handleFilterValue.emit({ [field]: 'reset-date' });
  }

  changeSortIcon(event: any): void {
    this.removeSelectedRow()
    this.sortColumn = event;
    this.first = 0;
    this.pageNumber = 0;
    this.draegerTable.first = 0;
    this.handleOnSortChange.emit(this.pageNumber);
  }

  onLocationChange(event: any, field: string) {
    this.removeSelectedRow()
    this.selectedCoordinates = event;
    this.handleFilterValue.emit({ [field]: event });
  }

  tooltipText(data: any) {
    return data?.join(' ; ')
  }

}
