import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Subject } from 'rxjs';
import { CommonConstants } from 'src/app/common';

@Component({
  selector: 'draeger-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginDisplay: boolean = false;
  errors: string;
  private readonly _destroying$: Subject<void> = new Subject<void>();

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  ngOnInit(): void {
    const error: string = localStorage.getItem(CommonConstants.LOGIN_ERROR);

    if (error) {
      this.errors = error;
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
  public async signIn(): Promise<void> {
    localStorage.clear();
    await this._oktaAuth.signInWithRedirect();
  }
  
}
