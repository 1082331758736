import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { OnDestroyMixin } from 'src/app/common/mixins/destroy-mixin';
import { AppUserPermissionList } from '../../models/app-types';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { IPermissionResponse, IRolePermission } from '../../state/role-management/models/role.model';
import { Store, select } from '@ngrx/store';
import { ApplicationState, DropdownService, IStoreApiItem } from 'src/app/common';
import { filter, map, take, takeUntil } from 'rxjs';
import { selectDrugCheckColumns, selectTestDataDrugCheckPage } from 'src/app/test-data-drugcheck/state/selectors/test-data-drugcheck.selector';
import { ITestDataDrugCheckPage } from 'src/app/test-data-drugcheck/models/test-data-drugcheck.model';
import { TestDataDrugCheckActions } from 'src/app/test-data-drugcheck/state/actions/test-data-drugcheck.actions';
import { TranslateService } from '@ngx-translate/core';
import { TestDataAlcotestActions } from 'src/app/test-data-alcotest/state/actions/test-data-alcotest.actions';
import { selectAlcotestColumns, selectTestDataAlcotestPage } from 'src/app/test-data-alcotest/state/selectors/test-data-alcotest.selector';
import { ITestDataAlcotestPage } from 'src/app/test-data-alcotest/models/test-data-alcotest.model';

@Component({
  selector: 'draeger-tabs',
  templateUrl: './tabs.component.html',
})
export class TabsComponent extends OnDestroyMixin() implements OnInit {
  @Input() permissions: IPermissionResponse;
  appUserPermissionList: any = AppUserPermissionList;

  columnOptionsDrugCheck: any;
  columnOptionsAlcotest: any;
  visibleDrugCheck: any = [];
  visibleAlcotest: any = [];
  drugCheckFilters: any;
  alcotestFilters: any;

  timeoutId: any;

  constructor(
    public permissionService: PermissionsService,
    public router: Router,
    private store: Store<ApplicationState>,
    private drugcheckActions: TestDataDrugCheckActions,
    private alcotestActions: TestDataAlcotestActions,
    public dropdownService: DropdownService,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    const hasStatisticsAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasStatisticsAccess);
    const hasDrugCheckAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasDrugCheckAccess);
    const hasAlcotestAccess: boolean = this.permissionService.checkPermission(this.permissions, AppUserPermissionList.hasAlcotestAccess);

    this.translate.onLangChange.subscribe()
    if (hasStatisticsAccess && this.router.url === '/') {
      this.router.navigate(['statistics']);
    } else if (!hasStatisticsAccess && hasAlcotestAccess && this.router.url === '/') {
      this.router.navigate(['test-data-alcotest']);
      this.initiateTestDataAlcotestPageSubscription();
    } else if (!hasStatisticsAccess && hasDrugCheckAccess && this.router.url === '/') {
      this.router.navigate(['test-data-drugcheck']);
      this.initiateTestDataDrugCheckPageSubscription();

    }

    if (this.router.url === '/test-data-drugcheck') {
      this.initiateTestDataDrugCheckPageSubscription();
    }
    if (this.router.url === '/test-data-alcotest') {
      this.initiateTestDataAlcotestPageSubscription();
    }

    //listens for language change and translates the options for the dropdowns.
    this.translate.onLangChange.subscribe((event) => {
      if (this.router.url === '/test-data-drugcheck') {
        this.initiateTestDataDrugCheckPageSubscription();
      }
      if (this.router.url === '/test-data-alcotest') {
        this.initiateTestDataAlcotestPageSubscription();
      }
    })
  }

  initiateTestDataDrugCheckPageSubscription() {
    this.store
      .pipe(
        select(selectTestDataDrugCheckPage),
        filter((state: IStoreApiItem<ITestDataDrugCheckPage>) => !state?.isLoading),
        map((state: IStoreApiItem<ITestDataDrugCheckPage>) => state?.data),
        takeUntil(this.destroy)
      )
      .subscribe((response: ITestDataDrugCheckPage) => {
        if (response) {
          this.columnOptionsDrugCheck = response.fields.map((field, index) => {
            if (field.can_be_displayed) {
              return {
                value: this.translate.instant(field.key), id: index, key: field.key, filter: field.filter
              }
            }
          })
          if (this.visibleDrugCheck.length === 0) {
            this.visibleDrugCheck = response.fields.map((field, index) => {
              if (field.is_displayed) {
                return index;
              }
            })
          }
        }
      });
  }

  initiateTestDataAlcotestPageSubscription() {
    this.store
      .pipe(
        select(selectTestDataAlcotestPage),
        filter((state: IStoreApiItem<ITestDataAlcotestPage>) => !state?.isLoading),
        map((state: IStoreApiItem<ITestDataAlcotestPage>) => state?.data),
        takeUntil(this.destroy)
      )
      .subscribe((response: ITestDataAlcotestPage) => {

        if (response) {
          this.columnOptionsAlcotest = response.fields
            .filter(field => field.can_be_displayed)
            .map((field, index) => ({
              value: this.translate.instant(field.key),
              id: index,
              key: field.key,
              filter: field.filter,
              is_displayed: field.is_displayed
            }));
          if (this.visibleAlcotest.length === 0) {
            this.visibleAlcotest = this.columnOptionsAlcotest.map((field, index) => {
              if (field.is_displayed) {
                return index;
              }
            })
          }
        }
      });
  }


  changeColumnVisibility(event: any, tableType: string) {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      let payload = {};
      if (tableType === 'drugcheck') {
        this.columnOptionsDrugCheck?.slice(1).forEach((column) => {
          payload[column.key] = event.includes(column.id) ? true : false;
        })
        this.drugcheckActions.requestUpdateDrugCheckColumns(payload);
        this.setSavedDrugCheckFilters(payload);
        this.initiateDrugCheckColumnsStore();

      }
      if (tableType === 'alcotest') {
        this.columnOptionsAlcotest.forEach((column) => {
          payload[column.key] = event.includes(column.id) ? true : false;
        })
        this.alcotestActions.requestUpdateAlcotestColumns(payload);
        this.setSavedAlcotestFilters(payload);
        this.initiateAlcotestColumnsStore();

      }
    }, 500);
  }

  getColumnOptions(tableType: string) {
    setTimeout(() => {
      if (tableType === 'drugcheck') {
        this.visibleDrugCheck = [];
        this.initiateTestDataDrugCheckPageSubscription();
      }
      if (tableType === 'alcotest') {
        this.visibleAlcotest = [];
        this.initiateTestDataAlcotestPageSubscription();
      }
    }, 1500);
  }

  initiateDrugCheckColumnsStore() {
    this.store
      .pipe(
        select(selectDrugCheckColumns),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        take(1)
      )
      .subscribe((response: any) => {
        if (response.isSuccess) {
          this.drugcheckActions.requestTestDataDrugCheckPage(this.drugCheckFilters);
        }
      });
  }

  initiateAlcotestColumnsStore() {
    this.store
      .pipe(
        select(selectAlcotestColumns),
        filter((state: IStoreApiItem<any>) => !state.isLoading),
        take(1)
      )
      .subscribe((response: any) => {
        if (response.isSuccess) {
          this.alcotestActions.requestTestDataAlcotestPage(this.alcotestFilters);
        }
      });
  }

  setSavedDrugCheckFilters(columnList: any): void {
    let temporaryFilter = JSON.parse(localStorage.getItem('testDataDrugCheckFilters'));
    this.drugCheckFilters = this.savedDrugCheckFilters;
    const filterKeys = Object.keys(this.savedDrugCheckFilters);
    this.columnOptionsDrugCheck.slice(1).forEach((option) => {
      if (filterKeys.includes(option.filter) && !columnList[option.key]) {
        delete this.drugCheckFilters[option.filter];
        delete temporaryFilter[option.filter];
      }
    })
    localStorage.setItem('temporaryDrugCheckFilters', JSON.stringify(this.drugCheckFilters));
    localStorage.setItem('testDataDrugCheckFilters', JSON.stringify(temporaryFilter))
  }

  setSavedAlcotestFilters(columnList: any): void {
    let temporaryFilter = JSON.parse(localStorage.getItem('testDataAlcotestFilters'));
    this.alcotestFilters = this.savedAlcotestFilters;
    const filterKeys = Object.keys(this.savedAlcotestFilters);
    this.columnOptionsAlcotest.forEach((option) => {
      if (filterKeys.includes(option.filter) && !columnList[option.key]) {
        delete this.alcotestFilters[option.filter];
        delete temporaryFilter[option.filter];
      }
      if ((filterKeys.includes('test_result_min') || filterKeys.includes('test_result_max')) && !columnList[option.key] && option.key === 'test_result') {
        delete this.alcotestFilters['test_result_min']
        delete temporaryFilter['test_result_min']
        delete this.alcotestFilters['test_result_max']
        delete temporaryFilter['test_result_max']
      }
    })
    localStorage.setItem('temporaryAlcotestFilters', JSON.stringify(this.alcotestFilters));
    localStorage.setItem('testDataAlcotestFilters', JSON.stringify(temporaryFilter))
  }

  get savedDrugCheckFilters(): any {
    return localStorage.getItem('temporaryDrugCheckFilters')
      ? JSON.parse(localStorage.getItem('temporaryDrugCheckFilters'))
      : null;
  }

  get savedAlcotestFilters(): any {
    return localStorage.getItem('temporaryAlcotestFilters')
      ? JSON.parse(localStorage.getItem('temporaryAlcotestFilters'))
      : null;
  }

}
