import { Component, Inject, OnInit } from '@angular/core'
import { AreaHeaderModule, AreaHeaderSize } from '@odx/angular/components/area-header';
import {
  LogoDirective,
  LogoSize,
  LogoVariant,
} from '@odx/angular/components/logo';
import { filter } from 'rxjs';
import OktaAuth, { AuthState } from '@okta/okta-auth-js';
import { OKTA_AUTH, OktaAuthStateService } from '@okta/okta-angular';
import { LinkDirective } from '@odx/angular/components/link';
import { TranslateModule } from '@ngx-translate/core';
import { OnDestroyMixin } from '../../mixins/destroy-mixin';

@Component({
  selector: 'draeger-user-avatar',
  standalone: true,
  imports: [AreaHeaderModule, LogoDirective, LinkDirective, TranslateModule],
  templateUrl: './user-avatar.component.html',
  styleUrl: './user-avatar.component.sass'
})
export class UserAvatarComponent extends OnDestroyMixin() implements OnInit {
  headerSize!: AreaHeaderSize;
  logoSize!: LogoSize;
  variant!: LogoVariant;
  userName!: string | undefined;
  userEmail!: any | undefined;
  userInitials!: string | undefined;

  constructor(
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth,
    private _oktaAuthStateService: OktaAuthStateService
  ) {
    super()
  }

  ngOnInit(): void {
    this.headerSize = 'large';
    this.variant = 'inverse';
    this.logoSize = 'small';

    this._oktaAuthStateService.authState$.pipe(
      filter((authState: AuthState) => !!authState && !!authState.isAuthenticated),

    ).subscribe((authState: AuthState) => {
      this.userName = authState.idToken?.claims.name ?? '';
      this.userEmail = authState.idToken?.claims.email_address ?? '';
      this.userInitials = authState.idToken?.claims.firstname[0] + ' ' + authState.idToken?.claims.lastname[0]
    })
  }


  async handleLogoutClick() {
    await this._oktaAuth.signOut().then(() => {
      localStorage.clear();
    });
  }
}
