export class CommonConstants {
  public static readonly notificationType: { [name: string]: string } = {
    SUCCESS: 'success',
    ERROR: 'error',
    WARNING: 'warning',
  };

  public static readonly logType: { [name: string]: string } = {
    DEBUG: 'debug',
    WARNING: 'warning',
    ERROR: 'error',
    INFO: 'info',
  };

  public static readonly modalType: { [name: string]: string } = {
    CREATE: 'create',
    UPDATE: 'update',
  };

  // eslint-disable-next-line
  public static readonly emailRegex: RegExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[\d/]{1,3}\.[\d/]{1,3}\.[\d/]{1,3}\.[\d/]{1,3}\])|(([a-zA-Z\-\d/]+\.)+[a-zA-Z]{2,}))$/;
  public static readonly whiteSpacesRegex: RegExp = /(?!^\s+$)^.*$/;
  public static readonly AUTH_TOKEN: string = 'AUTH_TOKEN';
  public static readonly LOGIN_ERROR: string = 'LOGIN_ERROR';
  public static readonly notificationDuration: number = 4000;

  public static readonly supportedLanguages: { [name: string]: string } = {
    'en-GB': 'en-GB',
    'de-DE': 'de-DE',
    'fr-FR': 'fr-FR',
    'es-ES': 'es-ES',
    'pl-PL': 'pl-PL',
  };

  public static readonly companyLanguages: Array<{ id: string, label: string }> = [
    { id: 'en_US', label: 'English' },
    { id: 'de_DE', label: 'German - Deutsch' },
    { id: 'es_ES', label: 'Spanish - Español' },
    { id: 'fr_FR', label: 'French - Français' },
    { id: 'it_IT', label: 'Italian - Italiano' },
    { id: 'ja_JP', label: 'Japanese - 日本語' },
    { id: 'pl_PL', label: 'Polish - Język polski' },
    { id: 'pt_BR', label: 'Portuguese - Português' },
    { id: 'tr_TR', label: 'Turkish - Türkçe' },
    { id: 'zh_CN', label: 'Chinese - 简体中文' },
  ];

  public static readonly companyCountries: Array<{ id: string, label: string }> = [
    { id: 'AF', label: 'afghanistan' },
    { id: 'AL', label: 'albania' },
    { id: 'DZ', label: 'algeria' },
    { id: 'AS', label: 'american_samoa' },
    { id: 'AD', label: 'andorra' },
    { id: 'AO', label: 'angola' },
    { id: 'AI', label: 'anguilla' },
    { id: 'AQ', label: 'antarctica' },
    { id: 'AG', label: 'antigua_and_barbuda' },
    { id: 'AR', label: 'argentina' },
    { id: 'AM', label: 'armenia' },
    { id: 'AW', label: 'aruba' },
    { id: 'AU', label: 'australia' },
    { id: 'AT', label: 'austria' },
    { id: 'AZ', label: 'azerbaijan' },
    { id: 'BS', label: 'bahamas' },
    { id: 'BH', label: 'bahrain' },
    { id: 'BD', label: 'bangladesh' },
    { id: 'BB', label: 'barbados' },
    { id: 'BY', label: 'belarus' },
    { id: 'BE', label: 'belgium' },
    { id: 'BZ', label: 'belize' },
    { id: 'BJ', label: 'benin' },
    { id: 'BM', label: 'bermuda' },
    { id: 'BT', label: 'bhutan' },
    { id: 'BO', label: 'bolivia' },
    { id: 'BQ', label: 'bonaire_sint_eustatius_and_saba' },
    { id: 'BA', label: 'bosnia_and_herzegovina' },
    { id: 'BW', label: 'botswana' },
    { id: 'BV', label: 'bouvet_island' },
    { id: 'BR', label: 'brazil' },
    { id: 'IO', label: 'british_indian_ocean_territory' },
    { id: 'BN', label: 'brunei_darussalam' },
    { id: 'BG', label: 'bulgaria' },
    { id: 'BF', label: 'burkina_faso' },
    { id: 'BI', label: 'burundi' },
    { id: 'CV', label: 'cabo_verde' },
    { id: 'KH', label: 'cambodia' },
    { id: 'CM', label: 'cameroon' },
    { id: 'CA', label: 'canada' },
    { id: 'KY', label: 'cayman_islands' },
    { id: 'CF', label: 'central_african_republic' },
    { id: 'TD', label: 'chad' },
    { id: 'CL', label: 'chile' },
    { id: 'CN', label: 'china' },
    { id: 'CX', label: 'christmas_island' },
    { id: 'CC', label: 'cocos_keeling_islands' },
    { id: 'CO', label: 'colombia' },
    { id: 'KM', label: 'comoros' },
    { id: 'CG', label: 'congo' },
    { id: 'CD', label: 'congo_democratic_republic' },
    { id: 'CK', label: 'cook_islands' },
    { id: 'CR', label: 'costa_rica' },
    { id: 'HR', label: 'croatia' },
    { id: 'CU', label: 'cuba' },
    { id: 'CW', label: 'curacao' },
    { id: 'CY', label: 'cyprus' },
    { id: 'CZ', label: 'czechia' },
    { id: 'DK', label: 'denmark' },
    { id: 'DJ', label: 'djibouti' },
    { id: 'DM', label: 'dominica' },
    { id: 'DO', label: 'dominican_republic' },
    { id: 'EC', label: 'ecuador' },
    { id: 'EG', label: 'egypt' },
    { id: 'SV', label: 'el_salvador' },
    { id: 'GQ', label: 'equatorial_guinea' },
    { id: 'ER', label: 'eritrea' },
    { id: 'EE', label: 'estonia' },
    { id: 'SZ', label: 'eswatini' },
    { id: 'ET', label: 'ethiopia' },
    { id: 'FK', label: 'falkland_islands_malvinas' },
    { id: 'FO', label: 'faroe_islands' },
    { id: 'FJ', label: 'fiji' },
    { id: 'FI', label: 'finland' },
    { id: 'FR', label: 'france' },
    { id: 'GF', label: 'french_guiana' },
    { id: 'PF', label: 'french_polynesia' },
    { id: 'TF', label: 'french_southern_territories' },
    { id: 'GA', label: 'gabon' },
    { id: 'GM', label: 'gambia' },
    { id: 'GE', label: 'georgia' },
    { id: 'DE', label: 'germany' },
    { id: 'GH', label: 'ghana' },
    { id: 'GI', label: 'gibraltar' },
    { id: 'GR', label: 'greece' },
    { id: 'GL', label: 'greenland' },
    { id: 'GD', label: 'grenada' },
    { id: 'GP', label: 'guadeloupe' },
    { id: 'GU', label: 'guam' },
    { id: 'GT', label: 'guatemala' },
    { id: 'GG', label: 'guernsey' },
    { id: 'GN', label: 'guinea' },
    { id: 'GW', label: 'guinea_bissau' },
    { id: 'GY', label: 'guyana' },
    { id: 'HT', label: 'haiti' },
    { id: 'HM', label: 'heard_island_and_mcdonald_islands' },
    { id: 'VA', label: 'holy_see' },
    { id: 'HN', label: 'honduras' },
    { id: 'HK', label: 'hong_kong' },
    { id: 'HU', label: 'hungary' },
    { id: 'IS', label: 'iceland' },
    { id: 'IN', label: 'india' },
    { id: 'ID', label: 'indonesia' },
    { id: 'IR', label: 'iran_islamic_republic_of' },
    { id: 'IQ', label: 'iraq' },
    { id: 'IE', label: 'ireland' },
    { id: 'IM', label: 'isle_of_man' },
    { id: 'IL', label: 'israel' },
    { id: 'IT', label: 'italy' },
    { id: 'JM', label: 'jamaica' },
    { id: 'JP', label: 'japan' },
    { id: 'JE', label: 'jersey' },
    { id: 'JO', label: 'jordan' },
    { id: 'KZ', label: 'kazakhstan' },
    { id: 'KE', label: 'kenya' },
    { id: 'KI', label: 'kiribati' },
    { id: 'KP', label: 'korea_democratic_people_s_republic_of' },
    { id: 'KR', label: 'korea_republic_of' },
    { id: 'KW', label: 'kuwait' },
    { id: 'KG', label: 'kyrgyzstan' },
    { id: 'LA', label: 'lao_people_s_democratic_republic' },
    { id: 'LV', label: 'latvia' },
    { id: 'LB', label: 'lebanon' },
    { id: 'LS', label: 'lesotho' },
    { id: 'LR', label: 'liberia' },
    { id: 'LY', label: 'libya' },
    { id: 'LI', label: 'liechtenstein' },
    { id: 'LT', label: 'lithuania' },
    { id: 'LU', label: 'luxembourg' },
    { id: 'MO', label: 'macao' },
    { id: 'MG', label: 'madagascar' },
    { id: 'MW', label: 'malawi' },
    { id: 'MY', label: 'malaysia' },
    { id: 'MV', label: 'maldives' },
    { id: 'ML', label: 'mali' },
    { id: 'MT', label: 'malta' },
    { id: 'MH', label: 'marshall_islands' },
    { id: 'MQ', label: 'martinique' },
    { id: 'MR', label: 'mauritania' },
    { id: 'MU', label: 'mauritius' },
    { id: 'YT', label: 'mayotte' },
    { id: 'MX', label: 'mexico' },
    { id: 'FM', label: 'micronesia_federated_states_of' },
    { id: 'MD', label: 'moldova_republic_of' },
    { id: 'MC', label: 'monaco' },
    { id: 'MN', label: 'mongolia' },
    { id: 'ME', label: 'montenegro' },
    { id: 'MS', label: 'montserrat' },
    { id: 'MA', label: 'morocco' },
    { id: 'MZ', label: 'mozambique' },
    { id: 'MM', label: 'myanmar' },
    { id: 'NA', label: 'namibia' },
    { id: 'NR', label: 'nauru' },
    { id: 'NP', label: 'nepal' },
    { id: 'NL', label: 'netherlands' },
    { id: 'NC', label: 'new_caledonia' },
    { id: 'NZ', label: 'new_zealand' },
    { id: 'NI', label: 'nicaragua' },
    { id: 'NE', label: 'niger' },
    { id: 'NG', label: 'nigeria' },
    { id: 'NU', label: 'niue' },
    { id: 'NF', label: 'norfolk_island' },
    { id: 'MK', label: 'north_macedonia' },
    { id: 'MP', label: 'northern_mariana_islands' },
    { id: 'NO', label: 'norway' },
    { id: 'OM', label: 'oman' },
    { id: 'PK', label: 'pakistan' },
    { id: 'PW', label: 'palau' },
    { id: 'PS', label: 'palestine_state_of' },
    { id: 'PA', label: 'panama' },
    { id: 'PG', label: 'papua_new_guinea' },
    { id: 'PY', label: 'paraguay' },
    { id: 'PE', label: 'peru' },
    { id: 'PH', label: 'philippines' },
    { id: 'PN', label: 'pitcairn' },
    { id: 'PL', label: 'poland' },
    { id: 'PT', label: 'portugal' },
    { id: 'PR', label: 'puerto_rico' },
    { id: 'QA', label: 'qatar' },
    { id: 'RE', label: 'reunion' },
    { id: 'RO', label: 'romania' },
    { id: 'RU', label: 'russian_federation' },
    { id: 'RW', label: 'rwanda' },
    { id: 'BL', label: 'saint_barthelemy' },
    { id: 'SH', label: 'saint_helena_ascension_and_tristan_da_cunha' },
    { id: 'KN', label: 'saint_kitts_and_nevis' },
    { id: 'LC', label: 'saint_lucia' },
    { id: 'MF', label: 'saint_martin_french_part' },
    { id: 'PM', label: 'saint_pierre_and_miquelon' },
    { id: 'VC', label: 'saint_vincent_and_the_grenadines' },
    { id: 'WS', label: 'samoa' },
    { id: 'SM', label: 'san_marino' },
    { id: 'ST', label: 'sao_tome_and_principe' },
    { id: 'SA', label: 'saudi_arabia' },
    { id: 'SN', label: 'senegal' },
    { id: 'RS', label: 'serbia' },
    { id: 'SC', label: 'seychelles' },
    { id: 'SL', label: 'sierra_leone' },
    { id: 'SG', label: 'singapore' },
    { id: 'SX', label: 'sint_maarten_dutch_part' },
    { id: 'SK', label: 'slovakia' },
    { id: 'SI', label: 'slovenia' },
    { id: 'SB', label: 'solomon_islands' },
    { id: 'SO', label: 'somalia' },
    { id: 'ZA', label: 'south_africa' },
    { id: 'GS', label: 'south_georgia_and_the_south_sandwich_islands' },
    { id: 'SS', label: 'south_sudan' },
    { id: 'ES', label: 'spain' },
    { id: 'LK', label: 'sri_lanka' },
    { id: 'SD', label: 'sudan' },
    { id: 'SR', label: 'suriname' },
    { id: 'SJ', label: 'svalbard_and_jan_mayen' },
    { id: 'SE', label: 'sweden' },
    { id: 'CH', label: 'switzerland' },
    { id: 'SY', label: 'syrian_arab_republic' },
    { id: 'TW', label: 'taiwan_province_of_china' },
    { id: 'TJ', label: 'tajikistan' },
    { id: 'TZ', label: 'tanzania_united_republic_of' },
    { id: 'TH', label: 'thailand' },
    { id: 'TL', label: 'timor_leste' },
    { id: 'TG', label: 'togo' },
    { id: 'TK', label: 'tokelau' },
    { id: 'TO', label: 'tonga' },
    { id: 'TT', label: 'trinidad_and_tobago' },
    { id: 'TN', label: 'tunisia' },
    { id: 'TR', label: 'turkey' },
    { id: 'TM', label: 'turkmenistan' },
    { id: 'TC', label: 'turks_and_caicos_islands' },
    { id: 'TV', label: 'tuvalu' },
    { id: 'UG', label: 'uganda' },
    { id: 'UA', label: 'ukraine' },
    { id: 'AE', label: 'united_arab_emirates' },
    { id: 'GB', label: 'united_kingdom' },
    { id: 'US', label: 'united_states_of_america' },
    { id: 'UM', label: 'united_states_minor_outlying_islands' },
    { id: 'UY', label: 'uruguay' },
    { id: 'UZ', label: 'uzbekistan' },
    { id: 'VU', label: 'vanuatu' },
    { id: 'VE', label: 'venezuela_bolivarian_republic_of' },
    { id: 'VN', label: 'vietnam' },
    { id: 'VG', label: 'virgin_islands_british' },
    { id: 'VI', label: 'virgin_islands_us' },
    { id: 'WF', label: 'wallis_and_futuna' },
    { id: 'EH', label: 'western_sahara' },
    { id: 'YE', label: 'yemen' },
    { id: 'ZM', label: 'zambia' },
    { id: 'ZW', label: 'zimbabwe' }
  ];

}
