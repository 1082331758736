import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common';
import { ApplicationState, IApplicationState } from './../../../common/state/models/app.state.model';
import { ITestDataAlcotestDetails, ITestDataAlcotestPage } from './../../models/test-data-alcotest.model';
import { ITestDataAlcotestState } from './../models/test-data-alcotest.model';

export const testDataAlcotestState = (state: IApplicationState) => state.testDataAlcotest;

export const selectTestDataAlcotestPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITestDataAlcotestPage>,
  DefaultProjectorFn<IStoreApiItem<ITestDataAlcotestPage>>
> = createSelector(testDataAlcotestState, (state: ITestDataAlcotestState) => state?.testDataAlcotestPage);

export const selectTestDataAlcotestDetails: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITestDataAlcotestDetails>,
  DefaultProjectorFn<IStoreApiItem<ITestDataAlcotestDetails>>
> = createSelector(testDataAlcotestState, (state: ITestDataAlcotestState) => state.testDataAlcotestDetails);

export const selectDeletedSection: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(testDataAlcotestState, (state: ITestDataAlcotestState) => state.testDataAlcotestDetails);

export const selectAlcotestColumns: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(testDataAlcotestState, (state: ITestDataAlcotestState) => state.testDataAlcotestColumns);
