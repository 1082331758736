import { createSelector, DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { IStoreApiItem } from 'src/app/common';
import { ApplicationState, IApplicationState } from './../../../common/state/models/app.state.model';
import { ITestDataDrugCheckDetails, ITestDataDrugCheckPage } from './../../models/test-data-drugcheck.model';
import { ITestDataDrugCheckState } from './../models/test-data-drugcheck.model';

export const testDataDrugCheckState = (state: IApplicationState) => state.testDataDrugCheck;

export const selectTestDataDrugCheckPage: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITestDataDrugCheckPage>,
  DefaultProjectorFn<IStoreApiItem<ITestDataDrugCheckPage | any>>
> = createSelector(testDataDrugCheckState, (state: ITestDataDrugCheckState) => state?.testDataDrugCheckPage);

export const selectTestDataDrugCheckDetails: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<ITestDataDrugCheckDetails>,
  DefaultProjectorFn<IStoreApiItem<ITestDataDrugCheckDetails>>
> = createSelector(testDataDrugCheckState, (state: ITestDataDrugCheckState) => state.testDataDrugCheckDetails);

export const selectDeletedSection: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(testDataDrugCheckState, (state: ITestDataDrugCheckState) => state.testDataDrugCheckDeletedSection);

export const selectDrugCheckColumns: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(testDataDrugCheckState, (state: ITestDataDrugCheckState) => state.testDataDrugCheckColumns);

export const selectLabResults: MemoizedSelector<
  ApplicationState,
  IStoreApiItem<any>,
  DefaultProjectorFn<IStoreApiItem<any>>
> = createSelector(testDataDrugCheckState, (state: ITestDataDrugCheckState) => state.testDataDrugCheckLabResults);
