import { CommonModule as AngularCommonModule } from '@angular/common';
import { HttpClient, HttpClientJsonpModule, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { CalendarModule } from 'primeng/calendar';
import { LoaderComponent } from './../components/loader/loader.component';
import { NotificationComponent } from './../components/notification/notification.component';
import { ProgressBarComponent } from './../components/progress-bar/progress-bar.component';
import { TableService } from './../services/table/table.service';
import { SortIconComponent } from '../components/sort-icon/sort-icon.component';
import { TableComponent } from '../components/table/table.component';
import { DropdownService } from '../services/dropdown/dropdown.service';
import { WindowService } from '../services/window/window.service';
import { AppHttpInterceptor } from '../services/app-interceptor/app-interceptor';
import { InputDebounceDirective } from '../directives/input-debounce.directive';
import { CrudFooterItemsComponent } from './../components/crud-footer-items/crud-footer-items.component';
import { DatePickerComponent } from '../components/datepicker/datepicker.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { MapsCoordinatesComponent } from '../components/maps-coordinates/maps-coordinates.component';
import { MapService } from '../services/map/map.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from './../utils/http-loader.factory';
import { TableV2Component } from '../components/table-v2/table-v2.component';
import { TooltipModule } from 'primeng/tooltip';
import { DetailsSectionComponent } from '../components/details-section/details-section.component';
import { MeasurementResultsComponent } from '../components/measurement-results/measurement-results.component';
import { ModalModule } from '@uib/angular/components/modal';
import { HeaderModule } from '@uib/angular/components/header';
import { IconModule } from '@uib/angular/components/icon';
import { ModalModule as ModalModuleOdx } from '@odx/angular/components/modal';
import { HeaderModule as OdxHeaderModule } from '@odx/angular/components/header';
import { DropdownModule as OdxDropdownModule } from "@odx/angular/components/dropdown"
import { IconComponent } from '@odx/angular/components/icon';
import { UserAvatarComponent } from '../components/user-avatar/user-avatar.component';
import { MainMenuModule } from '@odx/angular/components/main-menu';
import { MenuModule } from '@odx/angular/components/menu';

@NgModule({
  declarations: [
    LoaderComponent,
    NotificationComponent,
    SortIconComponent,
    TableComponent,
    DatePickerComponent,
    ProgressBarComponent,
    MapsCoordinatesComponent,
    InputDebounceDirective,
    CrudFooterItemsComponent,
    TableV2Component,
    DetailsSectionComponent,
    MeasurementResultsComponent,
  ],
  imports: [
    AngularCommonModule,
    FormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MultiSelectModule,
    DropdownModule,
    TableModule,
    CalendarModule,
    GoogleMapsModule,
    HeaderModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    ModalModule,
    IconModule,
    ModalModuleOdx,
    OdxHeaderModule,
    OdxDropdownModule,
    IconComponent,
    UserAvatarComponent,
    MainMenuModule,
    MenuModule
  ],
  exports: [
    LoaderComponent,
    ProgressBarComponent,
    SortIconComponent,
    TableComponent,
    InputDebounceDirective,
    CrudFooterItemsComponent,
    NotificationComponent,
    DatePickerComponent,
    TranslateModule,
    TableV2Component,
    DetailsSectionComponent,
    MeasurementResultsComponent,
    ModalModule,
    AngularCommonModule,
    HeaderModule,
    IconModule,
    TooltipModule,
    ModalModuleOdx,
    OdxHeaderModule,
    OdxDropdownModule,
    IconComponent,
    UserAvatarComponent,
    MainMenuModule,
    MenuModule
  ],
  providers: [
    WindowService,
    DropdownService,
    TableService,
    MapService,
    { provide: HTTP_INTERCEPTORS, useClass: AppHttpInterceptor, multi: true },
  ],
})
export class CommonModule { }
