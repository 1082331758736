<div
  [ngClass]="
    type === notificationType.SUCCESS
      ? 'uib-notification uib-notification--success'
      : type === notificationType.ERROR
      ? 'uib-notification uib-notification--critical'
      : type === notificationType.WARNING
      ? 'uib-notification uib-notification--warning'
      : ''
  "
  *ngIf="isNotificationVisible"
>
  <div class="uib-notification__title">
    <p>
      {{ message | translate }}
    </p>
  </div>

  <div class="uib-notification__actions">
    <button
      type="button"
      class="uib-button uib-button--primary"
      (click)="closeNotification()"
    >
      {{ "ok" | translate }}
    </button>
  </div>
</div>
