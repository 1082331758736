<span
  class="uib-icon uib-icon--arrow-scroll-up ml-7"
  *ngIf="columnSorted?.order === 1 && columnSorted?.field === columnName"
></span>

<span
  class="uib-icon uib-icon--arrow-scroll-down ml-7"
  *ngIf="columnSorted?.order === -1 && columnSorted?.field === columnName"
></span>

<span
  class="uib-icon uib-icon--arrow-scroll-down ml-7"
  *ngIf="columnSorted?.field !== columnName"
></span>
