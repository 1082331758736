<div class="test-data__content-right-column-container" *ngIf="data">
  <div
    class="test-data__content-right-column-wrap-card--top-right"
    *ngIf="data.delete_link"
  >
    <button
      odxButton
      variant="highlight"
      type="button"
      [disabled]="!data.delete_link"
      (click)="onDeleteClick($event, data.delete_link)"
    >
      {{
        data.section_title?.toLowerCase() === "donor_data"
          ? ("delete_donor_data" | translate)
          : ("delete_operator_data" | translate)
      }}
    </button>
  </div>
  <div class="test-data__results-header">
    <div class="test-data__results-header-title">
      <strong>{{ data.section_title | translate }}</strong>
    </div>
    <ng-container *ngIf="data.section_fields[0]?.field_title === 'status'">
      <div class="test-data__results-header-title" style="margin-left: 100px">
        {{ data.section_fields[0]?.field_title | translate }} :
      </div>
      <div
        class="test-data__results-dot"
        [ngStyle]="{
          background: data?.section_fields[0]?.field_value_color
        }"
      ></div>
      <div class="test-data__results-card-header-status">
        {{
          data?.section_fields[0]?.field_value_text
            ? (data?.section_fields[0].field_value_text | translate)
            : "-"
        }}
      </div>
    </ng-container>
  </div>

  <div
    class="test-data__content-right-column-card-row--small mt-2"
    *ngIf="
      data.section_title === 'workflow_alcotest_test_result' ||
      data.section_title === 'alcotest_result'
    "
  >
    <div
      class="test-data__content-right-column-wrap-card"
      *ngIf="data.first_alcotest_icons?.length > 0"
    >
      <p *ngIf="data.section_title === 'workflow_alcotest_test_result'">
        First alcotest
      </p>
      <ng-container *ngFor="let icon of data.first_alcotest_icons">
        <ng-container *ngFor="let item of alcotestIcons">
          <img
            *ngIf="icon === item.value"
            class="alcotest_icon"
            [src]="item.path"
            [alt]="item.alt"
            pTooltip="{{ item.tooltip }}"
            tooltipPosition="bottom"
          />
        </ng-container>
      </ng-container>
    </div>
    <div
      class="test-data__content-right-column-wrap-card"
      *ngIf="data.second_alcotest_icons?.length > 0"
    >
      <p>Second alcotest</p>
      <ng-container *ngFor="let icon of data.second_alcotest_icons">
        <ng-container *ngFor="let item of alcotestIcons">
          <img
            *ngIf="icon === item.value"
            class="alcotest_icon"
            [src]="item.path"
            [alt]="item.alt"
            pTooltip="{{ item.tooltip }}"
            tooltipPosition="bottom"
          />
        </ng-container>
      </ng-container>
    </div>
  </div>

  <br />

  <div class="test-data__content-right-column-card-row--small">
    <ng-container *ngIf="data.section_fields[0]?.field_title === 'status'">
      <div
        class="test-data__content-right-column-wrap-card"
        *ngFor="let field of data.section_fields.slice(1)"
      >
        <p>{{ field.field_title | translate }}</p>
        <p>
          @if(field.field_title.includes('timestamp') ||
          field.field_title.includes('date') ) {
          {{
            field.field_value !== "-"
              ? (field.field_value | date : "dd/MM/YYYY - HH:mm")
              : field.field_value
          }}
          } @else {
          {{
            field.can_be_translated
              ? (field.field_value | translate)
              : field.field_value
          }}
          }
        </p>
      </div>
    </ng-container>

    <ng-container *ngIf="data.section_fields[0]?.field_title !== 'status'">
      <div
        class="test-data__content-right-column-wrap-card"
        *ngFor="let field of data.section_fields"
      >
        <p>{{ field.field_title | translate }}</p>
        <p>
          @if(field.field_title.includes('timestamp')) {
          {{
            field.field_value !== "-"
              ? (field.field_value | date : "dd/MM/YYYY - HH:mm")
              : field.field_value
          }}
          } @else if (field.field_title.includes('Time')||
          field.field_title.includes('LabCollectionSample')) {
          {{
            field.field_value !== "-"&& field.field_value.length>5
              ? (field.field_value | date : "HH:mm")
              : field.field_value
          }}
          } @else {
          {{
            field.can_be_translated
              ? (field.field_value | translate)
              : field.field_value
          }}
          }
        </p>
      </div>
    </ng-container>
  </div>
</div>
