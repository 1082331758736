import { ITableColumn } from '../models/table.model';

// eslint-disable-next-line no-shadow
export enum TableFilterType {
  TEXT = 'TEXT',
  NUMBER = 'NUMBER',
  DATE = 'DATE',
  MULTI_SELECT = 'MULTI_SELECT',
  DROPDOWN = 'DROPDOWN',
  MAPS = 'MAPS',
  RANGE = 'RANGE',
}

export class TableConstants {

  public static readonly isActiveOptions: object[] = [{ value: true, label: 'active' }, { value: false, label: 'inactive' }];
  public static readonly companyAccess: object[] = [{ value: 'alcotest_access', label: 'alcotest_access' }, { value: 'drugcheck_access', label: 'drugcheck_access' }];
  public static readonly companiesTableAccess: object[] = [{
    value: 'alcotest', label: 'alcotest'
  }, { value: 'drugtest', label: 'drugtest' }, { value: 'combined', label: 'combined' }
  ]
  public static readonly userManagementColumns: ITableColumn[] = [
    {
      field: 'first_name',
      header: 'first_name',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '140px'
    },
    {
      field: 'last_name',
      header: 'last_name',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '140px'
    },
    {
      field: 'id',
      header: 'id',
      visible: true,
      filterType: TableFilterType.NUMBER,
      width: '100px'
    },
    {
      field: 'email',
      header: 'email',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '190px'
    },
    {
      field: 'phone',
      header: 'phone',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '180px'
    },
    {
      field: 'is_active',
      header: 'status',
      visible: true,
      filterType: TableFilterType.DROPDOWN,
      width: '120px',
      dropdownOptions: TableConstants.isActiveOptions,
    },
    {
      field: 'role_short_name',
      header: 'role',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      textAlign: 'center',
      width: '150px'
    },
    {
      field: 'company_name',
      header: 'company',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '190px'
    },
    {
      field: 'donor_company_name',
      header: 'donor_company',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '200px'
    },
    {
      field: 'donor_companies',
      header: 'donor_companies',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '200px'
    },
  ];

  public static readonly companiesColumns: ITableColumn[] = [
    {
      field: 'name',
      header: 'company_name',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '260px'
    },
    {
      field: 'address',
      header: 'address',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '320px'
    },
    {
      field: 'second_address',
      header: 'second_address',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '320px'
    },
    {
      field: 'phone',
      header: 'phone',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '180px'
    },
    {
      field: 'email',
      header: 'email',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '290px'
    },
    {
      field: 'access',
      header: 'access',
      visible: true,
      filterType: TableFilterType.DROPDOWN,
      width: '180px',
      dropdownOptions: TableConstants.companiesTableAccess
    },
  ];

  public static readonly testDataAlcotestColumns: ITableColumn[] = [
    {
      field: 'device_test_id',
      header: 'test_number',
      visible: true,
      filterType: TableFilterType.NUMBER,
      width: '130px',
      textAlign: 'center',
    },
    {
      field: 'test_result',
      header: 'test_result',
      visible: true,
      filterType: TableFilterType.RANGE,
      width: '130px',
      textAlign: 'center',
    },
    {
      field: 'device_name',
      header: 'device',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '200px'
    },
    {
      field: 'timestamp',
      header: 'date_time',
      visible: true,
      filterType: TableFilterType.DATE,
      width: '230px'
    },
    {
      field: 'position_dd2dms',
      header: 'gps_position',
      visible: true,
      filterType: TableFilterType.MAPS,
      width: '200px',
      position: 'relative'
    },
    {
      field: 'company_name',
      header: 'company',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '200px'
    },
    {
      field: 'tester_name',
      header: 'tester',
      visible: true,
      filterType: TableFilterType.TEXT,
      width: '200px'
    },
  ];

  public static readonly activeDevicesColumns: ITableColumn[] = [
    {
      field: 'serial_number',
      header: 'serial_number',
      visible: true,
      width: '150px',
      filterType: TableFilterType.MULTI_SELECT,
    },
    {
      field: 'created',
      header: 'first_upload_date',
      visible: true,
      width: '150px',
    },
    {
      field: 'first_test_date',
      header: 'first_test_date',
      visible: true,
      width: '150px'
    },
    {
      field: 'last_upload',
      header: 'last_upload',
      visible: true,
      width: '150px'
    },
    {
      field: 'total_tests',
      header: 'total_tests',
      visible: true,
      width: '150px',
    },
    {
      field: 'last_calibration',
      header: 'last_calibration',
      visible: true,
      width: '150px'
    },
    {
      field: 'company_name',
      header: 'company_name',
      visible: true,
      filterType: TableFilterType.MULTI_SELECT,
      width: '150px'
    }
  ];

  public static readonly calibrationAccuracyColumns: ITableColumn[] = [
    {
      field: 'serial_number',
      header: 'serial_number',
      visible: true,
      width: '150px',
      filterType: TableFilterType.MULTI_SELECT,
    },
    {
      field: 'date',
      header: 'date',
      visible: true,
      width: '150px',
      filterType: TableFilterType.DATE,
    },
    {
      field: 'type_meas_1',
      header: 'type_meas_1',
      visible: true,
      width: '150px',
      filterType: TableFilterType.MULTI_SELECT,
    },
    {
      field: 'result_meas_1',
      header: 'result_meas_1',
      visible: true,
      width: '150px'
    },
    {
      field: 'time_meas_1',
      header: 'time_meas_1',
      visible: true,
      width: '150px',
    },
    {
      field: 'type_meas_2',
      header: 'type_meas_2',
      visible: true,
      width: '150px',
      filterType: TableFilterType.MULTI_SELECT,
    },
    {
      field: 'result_meas_2',
      header: 'result_meas_2',
      visible: true,
      width: '150px'
    },
    {
      field: 'time_meas_2',
      header: 'time_meas_2',
      visible: true,
      width: '150px',
    },
    {
      field: 'type_meas_3',
      header: 'type_meas_3',
      visible: true,
      width: '150px',
      filterType: TableFilterType.MULTI_SELECT,
    },
    {
      field: 'result_meas_3',
      header: 'result_meas_3',
      visible: true,
      width: '150px'
    },
    {
      field: 'time_meas_3',
      header: 'time_meas_3',
      visible: true,
      width: '150px',
    },
  ];

  public static readonly rowsPerPageOptions: number[] = [10, 25, 50, 100, 500];
}
