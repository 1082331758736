import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CommonConstants, NotificationActions } from 'src/app/common';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private notificationActions: NotificationActions,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const jwtHelper: JwtHelperService = new JwtHelperService();

        console.error('SERVER ERROR => Error from error interceptor:', error);

        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          jwtHelper.isTokenExpired(localStorage.getItem(CommonConstants.AUTH_TOKEN)) &&
          localStorage.getItem(CommonConstants.AUTH_TOKEN)
        ) {
          this.refreshToken();
        } else {
          this.notificationActions.requestShowNotification(
            CommonConstants.notificationType.ERROR,
            'An error occurred. Please try again later.'
          );
        }


        if (error?.error?.detail === 'Invalid signature.') {
          this.logout('Invalid signature.');
        }

        if (jwtHelper.isTokenExpired(this._oktaAuth.getAccessToken())) {
          this.logout();
        }

        if (error?.error?.detail === 'no_user_account_found') {
          this.logout('no_user_account_found')
        }
        if (error?.error?.detail === 'user_account_disabled') {
          this.logout('user_account_disabled')
        }
        if (error?.error?.detail === 'user_account_not_active') {
          this.logout('User account not active.')
        }

        return throwError(() => new HttpErrorResponse(error));
      })
    );
  }

  refreshToken(): void {
    localStorage.setItem(CommonConstants.AUTH_TOKEN, this._oktaAuth.getAccessToken())
    const currentUrl: string = this.router.url;
    this.router.navigate([currentUrl]);
  }

  async logout(error?: string) {
    await this._oktaAuth.signOut().then(() => {
      localStorage.clear();
      localStorage.setItem(CommonConstants.LOGIN_ERROR, error ? error : 'Please try again later.');
    });
  }
}
