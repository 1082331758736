import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  /* istanbul ignore next */
  public openDropdown(dropdownRef: any): void {
    dropdownRef.dropdownIcon = 'uib-icon uib-icon--arrow-scroll-up custom-dropdown-icon';
  }

  /* istanbul ignore next */
  public closeDropdown(dropdownRef: any): void {
    dropdownRef.dropdownIcon = 'uib-icon uib-icon--arrow-scroll-down custom-dropdown-icon';
  }
}
