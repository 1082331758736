<div
  class="uib-dropdown"
  [ngClass]="{
    'datepicker-error': showError
  }"
>
  <p-calendar
    yearRange="2000:2030"
    [appendTo]="appendTo"
    [selectionMode]="selectionMode"
    [showTime]="showTime"
    [dateFormat]="
      monthView
        ? 'mm/yy'
        : formatDate?.replace('MM', 'mm')?.replace('yyyy', 'yy')
    "
    [placeholder]="placeholder"
    [readonlyInput]="readOnly"
    [showSeconds]="showSeconds"
    [minDate]="minDateAsDate"
    [maxDate]="maxDateAsDate"
    [(ngModel)]="dateFilters"
    [yearNavigator]="yearNavigator"
    [showIcon]="true"
    [firstDayOfWeek]="1"
    [numberOfMonths]="numberOfMonths"
    (onSelect)="onSelect()"
    (onInput)="editManuallyStart($event)"
    (onBlur)="checkIfDateIsInvalid($event)"
    (change)="onChange($event)"
    [showTransitionOptions]="'0ms'"
    [hideTransitionOptions]="'0ms'"
    [readonlyInput]="readOnly"
    [disabled]="disabled"
    [view]="monthView ? 'month' : 'date'"
  >
  </p-calendar>

  <span
    class="p-dropdown-clear-icon pi pi-times reset-value"
    *ngIf="dateFilters && clearable"
    (click)="resetRange()"
  ></span>
</div>
