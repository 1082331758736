<div [ngClass]="{ 'hide-element': isLoading }">
  <draeger-container
    *ngIf="userLoggedIn; else loginDisplay"
    [permissions]="permissions"
    [userLoggedIn]="userLoggedIn"
    [userUnauthorized]="userUnauthorized"
  ></draeger-container>

  <ng-template #loginDisplay>
    <draeger-login></draeger-login>
  </ng-template>

  <router-outlet></router-outlet>
</div>

<div *ngIf="isLoading" class="homepage-loader">
  <div class="spinner-border spinner" role="status"></div>
</div>
