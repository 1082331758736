import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { CommonConstants } from '../../constants/common.constants';
import { IApplicationState } from '../../state/models/app.state.model';
import { INotificationState, NotificationTypes } from '../../state/notifications/models/notification.model';
import { selectNotification } from '../../state/notifications/selectors/notification.selector';

@Component({
  selector: 'draeger-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
})
export class NotificationComponent implements OnInit {
  message: string;
  value: string;
  category: string;
  notificationState: INotificationState;
  isNotificationVisible: boolean = false;

  type: NotificationTypes;
  destroy$: Subject<boolean> = new Subject<boolean>();

  notificationType: { [name: string]: string } = CommonConstants.notificationType;

  constructor(private store: Store<IApplicationState>) { }

  ngOnInit(): void {
    this.store
      .pipe(
        select(selectNotification),
        takeUntil(this.destroy$),
        filter((notificationState: INotificationState) => !!notificationState?.type && !!notificationState.message)
      )
      .subscribe((notificationState: INotificationState) => {
        this.type = notificationState.type;
        this.message = notificationState.message;
        this.value = notificationState.value;
        this.category = notificationState.category;

        this.isNotificationVisible = true;

        if (notificationState.type === CommonConstants.notificationType.SUCCESS) {
          setTimeout(() => {
            this.isNotificationVisible = false;
          }, CommonConstants.notificationDuration);
        }
      });
  }

  closeNotification(): void {
    this.isNotificationVisible = false;
  }
}
