<div class="tabbar">
  <div class="uib-tabbar mt-2">
    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasStatisticsAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['statistics']"
      routerLinkActive="is-active"
    >
      {{ "statistics" | translate }}
    </button>

    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasAlcotestAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['test-data-alcotest']"
      routerLinkActive="is-active"
      (click)="getColumnOptions('alcotest')"
    >
      {{ "test_data_alcotest" | translate }}
    </button>

    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasDrugCheckAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['test-data-drugcheck']"
      routerLinkActive="is-active"
      (click)="getColumnOptions('drugcheck')"
    >
      {{ "test_data_drugcheck" | translate }}
    </button>

    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasDevicesAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['active-devices']"
      routerLinkActive="is-active"
    >
      {{ "active_devices" | translate }}
    </button>

    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasCompaniesAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['companies']"
      routerLinkActive="is-active"
    >
      {{ "companies" | translate }}
    </button>

    <button
      *ngIf="
        permissionService.checkPermission(
          permissions,
          appUserPermissionList.hasUsersAccess
        )
      "
      class="uib-tabbar__tab"
      [routerLink]="['user-management']"
      routerLinkActive="is-active"
    >
      {{ "users" | translate }}
    </button>
  </div>
  <div
    *ngIf="
      router.url === '/test-data-drugcheck' && !router.url?.includes('details')
    "
    class="column-filter"
  >
    <p-multiSelect
      ngDefaultControl
      dropdownIcon="uib-icon uib-icon--arrow-scroll-down custom-dropdown-icon"
      class="multiselect-float"
      [appendTo]="'body'"
      [ngModel]="visibleDrugCheck"
      [options]="columnOptionsDrugCheck?.slice(1)"
      (onChange)="changeColumnVisibility($event.value, 'drugcheck')"
      optionLabel="value"
      optionValue="id"
      [placeholder]="'visible_columns' | translate"
      defaultLabel="Visible Columns"
      [disabled]="columnOptionsDrugCheck?.length === 0"
      [filter]="true"
      #dd
      (onPanelShow)="dropdownService.openDropdown(dd)"
      (onPanelHide)="dropdownService.closeDropdown(dd)"
      [resetFilterOnHide]="true"
    >
      <ng-template let-value pTemplate="selectedItems">
        <span>{{ "columns" | translate }}</span>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span
          tooltipPosition="bottom"
          pTooltip="{{ option.value }}"
          style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
          >{{ option.value }}</span
        >
      </ng-template>
    </p-multiSelect>
  </div>

  <div
    *ngIf="
      router.url === '/test-data-alcotest' && !router.url?.includes('details')
    "
    class="column-filter"
  >
    <p-multiSelect
      ngDefaultControl
      dropdownIcon="uib-icon uib-icon--arrow-scroll-down custom-dropdown-icon"
      class="multiselect-float"
      [appendTo]="'body'"
      [ngModel]="visibleAlcotest"
      [options]="columnOptionsAlcotest"
      (onChange)="changeColumnVisibility($event.value, 'alcotest')"
      optionLabel="value"
      optionValue="id"
      [placeholder]="'visible_columns' | translate"
      defaultLabel="Visible Columns"
      [disabled]="columnOptionsAlcotest?.length === 0"
      [filter]="true"
      #dd1
      (onPanelShow)="dropdownService.openDropdown(dd1)"
      (onPanelHide)="dropdownService.closeDropdown(dd1)"
      [resetFilterOnHide]="true"
    >
      <ng-template let-value pTemplate="selectedItems">
        <span>{{ "columns" | translate }}</span>
      </ng-template>
      <ng-template let-option pTemplate="item">
        <span
          tooltipPosition="bottom"
          pTooltip="{{ option.value }}"
          style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
          >{{ option.value }}</span
        >
      </ng-template>
    </p-multiSelect>
  </div>
</div>
