<div id="wrapper" *ngIf="userLoggedIn && !userUnauthorized">
  <draeger-navbar [permissions]="permissions"></draeger-navbar>
  <draeger-notification></draeger-notification>
  <draeger-tabs
    *ngIf="
      !router.url?.includes('details') &&
      !router.url?.includes('calibration-accuracy')
    "
    [permissions]="permissions"
  ></draeger-tabs>
</div>
