<div class="uib-root">
  <uib-header></uib-header>
  <main role="main" class="uib-main">
    <div class="uib-board uib-board--centered">
      <div class="uib-wizard c-wizard-login">
        <div class="uib-wizard__content">
          <div class="uib-wizard__header">
            <div class="c-wizard-login__title">
              {{ "login_welcome" | translate }}
            </div>
          </div>
          <div class="uib-wizard__body c-wizard-login__body">
            <button
              class="uib-button uib-button--primary c-wizard-login__button"
              (click)="signIn()"
            >
              {{ "login" | translate }}
            </button>
          </div>
          <div *ngIf="errors" class="error-login">
            {{ "login_error" | translate }} <br />
            {{ errors | translate }}
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
